import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import Contact from "../components/index/Contact"
import dstvpic from '../assets/images/DStvPrices.png'
import gotvpic from '../assets/images/GOtvPrices.png'
import paydstv from '../assets/images/paydstv.png'
import { BsFillCheckCircleFill } from "react-icons/bs";


const Entertainment = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <div id='entertainment-page'>
        <div className="entertainment-header">
            <div className="entertain-container">
                <h4>Experience a whole new world on DSTV</h4>
                <p>subscribe and enjoy amazing entertainment</p>
                {/* <button>SUBSCRIBE</button> */}
            </div>
        </div>
        <div className="choose-plan">
            <h6>Current DStv Plans</h6>
            <div className="dstv-sub sub-pack">
              <img data-aos="fade-down" src= {dstvpic} alt="" />
            </div>

        </div>
        <div className="choose-plan">
            <h6>Current GOtv Plans</h6>
            <div className="dstv-sub sub-pack">
              <img data-aos="fade-down" src= {gotvpic} alt="" />
            </div>
        </div>
        <div className="paydstv">
          <div data-aos="fade-down" className="how-to-pay-dstv lift">
            <h6>How to pay for your dstv Subscription</h6>
            <div className="payment-steps">
              <p data-aos="fade-right" data-aos-delay="100"><BsFillCheckCircleFill className="check-service" /> fill the form below</p>
              <p data-aos="fade-right" data-aos-delay="200"><BsFillCheckCircleFill className="check-service" /> enter your dstv infromation</p>
              <p data-aos="fade-right" data-aos-delay="300"><BsFillCheckCircleFill className="check-service" /> make payment through our secure payment methods</p>
              <p data-aos="fade-right" data-aos-delay="400"><BsFillCheckCircleFill className="check-service" /> enjoy your subscription</p>
            </div>

          </div>
          <div data-aos="fade-left"className="how-to-pay-img lift">
            <img src= {paydstv} alt="" />
          </div>
          

        </div>

    <Contact />
    </div>
  )
}

export default Entertainment