import React, { useState, useCallback, useEffect } from "react";
import {
  Marker,
  GoogleMap,
  useJsApiLoader,
  InfoWindow,
} from "@react-google-maps/api";

const containerStyle = {
  width: "100%",
  height: "300px",
};

const Map = () => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API,
  });

  const [map, setMap] = useState(null);
  const [location, setLocation] = useState({
    lat: 6.47129193779943,
    lng: 3.281203269308258,
  });
  const [zoom, setZoom] = useState(15);
  const [infoOpen, setInfoOpen] = useState(false);
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [marker, setMarker] = useState({});

  const onLoad = useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds();
    map.fitBounds(bounds);
    map.setZoom(17);
    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);
  const markerLoadHandler = (marker) => {
    return setMarker(marker);
  };

  const markerClickHandler = () => {
    setSelectedPlace(location);
    // Required so clicking a 2nd marker works as expected
    if (infoOpen) {
      setInfoOpen(false);
    }

    setInfoOpen(true);

    console.log("zoom", zoom);
    // If you want to zoom in a little on marker click
    if (zoom < 16) {
      setZoom(16);
    }

    // if you want to center the selected Marker
    //setCenter(place.pos)
  };
  useEffect(() => {
    setTimeout(() => {
      setZoom(16);
    }, 3000);
  }, []);
  return (
    <div className="map">
      <div className="google-map">
        {isLoaded ? (
          <GoogleMap
            mapContainerStyle={containerStyle}
            onCenterChanged={() => {
              // This is here to force it to center where i want it to be
              // It was misbehaving
              // Figure out zoom
              if (map?.getCenter().toJSON().lat !== location.lat) {
                setLocation({
                  lat: 6.47129193779943,
                  lng: 3.281203269308258,
                });
                map?.setZoom(14);
                setZoom(14);
              }
            }}
            center={location}
            zoom={zoom}
            onLoad={onLoad}
            onUnmount={onUnmount}
          >
            <Marker
              position={location}
              onLoad={(marker) => markerLoadHandler(marker)}
              onClick={() => markerClickHandler()}
            />
            {infoOpen && selectedPlace && (
              <InfoWindow
                anchor={marker}
                onCloseClick={() => setInfoOpen(false)}
              >
                <div>
                  <h5>Chap Idyzz Global</h5>
                  <div>
                    <p>Chap Idyzz Global Ltd</p>
                    <p>512 51 Rd,</p>
                    <p>Festac Town 102102,</p>
                    <p>Lagos</p>
                  </div>
                </div>
              </InfoWindow>
            )}
          </GoogleMap>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default Map;
