import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Aos from "aos";
import { AiFillApi } from "react-icons/ai";
import { BsFillCheckCircleFill } from "react-icons/bs";

import flight from "assets/images/flight.png";
import networkCable from "assets/images/network2.jpg";
import multichoice from "assets/images/multichoice.png";

const Dstv = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <div>
      {/* ------------------------------------------------------------------------------ */}
      <div className="choose-us">
        <div data-aos="fade-up" className="choose-us-text lift">
          <h4>
            <AiFillApi /> Why Choose Us
          </h4>
          <p>
            ChapIdyzz is an evolving networking company that provides high end
            networking services such as interconnection, wi-fi and newly added
            flight services. We have you covered with all interconnection and
            travel services, all to ensure that you get the best of what you
            need at your comfort
          </p>
          <p>
            <BsFillCheckCircleFill className="check-service" /> High Speed
            internet connectivity
          </p>
          <p>
            <BsFillCheckCircleFill className="check-service" /> 100% comfort
            guaranteed
          </p>
          <p>
            <BsFillCheckCircleFill className="check-service" /> 3 months post
            support services
          </p>
        </div>

        <div data-aos="fade-left" className="choose-us-img lift">
          <img src={networkCable} alt="" />
        </div>
      </div>
      {/* --------------------------------------------------------------------------------- */}

      <div className="choose-us">
        <div data-aos="fade-down" className="choose-us-img lift">
          <img src={multichoice} alt="" />
        </div>
        <div data-aos="fade-up" className="choose-us-text support lift">
          <h4>
            <AiFillApi /> Subscribe to DSTV/ GOTV
          </h4>
          <p>
            Hospitality and Private TV entertainment package to suit your needs
          </p>
        </div>
      </div>
      {/* --------------------------------------------------------------------------------- */}
      <div className="choose-us">
        <div data-aos="fade-right" className="choose-us-text lift">
          <h4>Chap Travels is Here!</h4>
          <p>
            we book only the most affordable fights for your next travel
            destination. Our services are both local and international and we
            ensure that you never have to waste hours trying to find affordable
            flights anymore; we do the work for you
          </p>
          <button className="common-btn flight-btn">
            <Link
              to="/flight_home"
              style={{ color: "white", textDecoration: "none" }}
            >
              Learn More
            </Link>
          </button>
        </div>
        <div data-aos="fade-left" className="choose-us-img lift">
          <img src={flight} alt="" />
        </div>
      </div>

      
    </div>
  );
};

export default Dstv;
