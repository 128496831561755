/** @format */

import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Layout from "./components/layout"
import HomePage from "./pages"
import FlightHome from "./pages/flightHome"
import ShopPage from "./pages/shop"
import Checkout from './pages/checkout'
import Entertainment from './pages/entertainment'

const MainRouter = () => {
	return (
    <>
		<Router>
			<Routes>
				<Route path="/" element={<Layout />}>
					<Route index element={<HomePage />} />
					<Route path="/shop" element={<ShopPage />} />
					<Route path="/flight_home" element={<FlightHome />} />
					<Route path="/checkout" element={<Checkout />} />
					<Route path="/entertainment" element={<Entertainment />} />
				</Route>
			</Routes>
		</Router>
      <ToastContainer />
    </>
  );
};

export default MainRouter;
