import React, { useEffect, useState } from "react";
import { useSWRConfig } from "swr";
import { GrSubtractCircle, GrAddCircle } from "react-icons/gr";
import { toast } from "react-toastify";

import {
  useAddCartItem,
  useRemoveCartItem,
  useChangeCartItemQuantity,
} from "store/cart";
import img from "assets/images/headerimg3.jpg";
import { ReactComponent as AddCirce } from "assets/svgs/add-circle.svg";

const Item = ({ item, cta, cartQuantity }) => {
  const { mutate } = useSWRConfig();
  const [localCartQuantity, setLocalCartQuantity] = useState(cartQuantity);
  const [localCTA, setLocalCTA] = useState(cta);
  const [addCartItem, setAddCartItem] = useState(false);
  const [removeCartItem, setRemoveCartItem] = useState(false);
  const [addCartItemQuantity, setAddCartItemQuantity] = useState(false);
  const [reduceCartItemQuantity, setReduceCartItemQuantity] = useState(false);

  const notifyAddToCart = () => {
    toast(`${item.PartNumber} has been successfully added to the cart`, {
      position: toast.POSITION.BOTTOM_RIGHT,
      className: "foo-bar",
    });
  };
  const notifyRemoveFromCart = () => {
    toast.warn(
      `${item.PartNumber} has been successfully removed from the cart`,
      {
        position: toast.POSITION.BOTTOM_RIGHT,
      }
    );
  };

  useAddCartItem({
    product: item,
    quantity: cartQuantity,
    shouldFetch: addCartItem,
  });
  useRemoveCartItem({
    product: item,
    quantity: cartQuantity,
    shouldFetch: removeCartItem,
  });
  useChangeCartItemQuantity({
    product: item,
    changeType: "increase",
    shouldFetch: addCartItemQuantity,
  });
  useChangeCartItemQuantity({
    product: item,
    changeType: "decrease",
    shouldFetch: reduceCartItemQuantity,
  });
  useEffect(() => {
    mutate("getCarts");
    if (addCartItem) {
      setTimeout(() => {
        setAddCartItem(false);
      }, 1000);
    }
  }, [addCartItem, mutate]);
  useEffect(() => {
    if (addCartItemQuantity) {
      setLocalCartQuantity((prev) => prev + 1);
      setTimeout(() => {
        setAddCartItemQuantity(false);
      }, 1000);
    }
    if (reduceCartItemQuantity) {
      setLocalCartQuantity((prev) => {
        if (prev - 1 === 0) {
          setLocalCTA("addToCart");
          toast.dismiss();
          notifyRemoveFromCart();
          setRemoveCartItem(true);
          return prev;
        }
        return prev - 1;
      });
      setTimeout(() => {
        setReduceCartItemQuantity(false);
        setRemoveCartItem(false);
      }, 1000);
    }
    mutate("changeCartItemQuantity");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addCartItemQuantity, reduceCartItemQuantity]);

  return (
    <div className="shop-item">
      <img className="item-img" src={img} alt="" />
      <div className="shop-item-txt">
        <h4>{item.PartNumber}</h4>
        <h5>Price: ${item.MSRP}</h5>
        <div className="description">
          <p>{item.Description}</p>
          {localCTA === "addToCart" ? (
            <button
              onClick={() => {
                notifyAddToCart();
                setAddCartItem(true);
                setLocalCTA("updateCart");
              }}
            >
              <AddCirce className="add-icon" />
            </button>
          ) : null}
          {localCTA === "updateCart" ? (
            <div>
              {/* Next is to make it such that when its 0 stop reducing and display the add button and add toast */}
              <button onClick={() => setReduceCartItemQuantity(true)}>
                <GrSubtractCircle />
              </button>
              <p>{localCartQuantity}</p>
              <button onClick={() => setAddCartItemQuantity(true)}>
                <GrAddCircle />
              </button>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Item;
