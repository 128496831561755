/** @format */

import React from "react"
import Header from "components/index/Header"
import About from "components/index/About"
import Services from "components/index/Services"
// import Network from "components/index/Network"
import Dstv from "components/index/Dstv"
// import Flight from "components/index/Flight"
import Contact from "../components/index/Contact"

const HomePage = () => {
	return (
		<div>
			<Header />
			<About />
			<Services />
			{/* <Network /> */}
			<Dstv />
			{/* <Flight /> */}
			<Contact />
			
		</div>
	)
}

export default HomePage
