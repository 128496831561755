/** @format */

import { useState } from 'react'
import { Link } from "react-router-dom"
// import { IoIosCart } from 'react-icons/io';
import { HiOutlineMenuAlt3 } from 'react-icons/hi';


const Nav = () => {
	const [showMenu, setShowMenu] = useState(false)

	return (
		<div className="nav">

			<ul className="nav-links">
				<div className="logo-text">
					<Link className="logo-text-link" to="/"><h3>Chap Idyzz</h3></Link>

				</div>
				<div className= {`link-items ${showMenu ? 'open' : undefined}`}>
					<li><Link to="/" onClick= {() => setShowMenu(!showMenu)}>HOME</Link></li>
					{/* <li><a href="/" onClick= {() => setShowMenu(!showMenu)}>ABOUT</a></li> */}
					{/* <li><Link to="/shop" onClick= {() => setShowMenu(!showMenu)}>SHOP</Link></li> */}
					<li><Link to="/entertainment" onClick= {() => setShowMenu(!showMenu)}>ENTERTAINMENT</Link></li>
					<li><Link to= "/flight_home" onClick= {() => setShowMenu(!showMenu)}>TRAVEL</Link></li>
				</div>

				<div className="cart-content">
					{/* <li>
						<Link to= "/checkout" style={{color: 'white', textDecoration: 'none'}}><IoIosCart className="nav-icon cart-icon" /></Link>
					</li> */}

					<li><HiOutlineMenuAlt3 className="nav-icon menu-icon"  onClick= {() => setShowMenu(!showMenu)}/></li>
				</div>
			</ul>
		</div>
	)
}

export default Nav
