/** @format */

import React from "react"
import { Outlet } from "react-router-dom"
import Footer from "./footer"
import Nav from "./Nav"

const Layout = () => {
	return (
		<div>
			<Nav />
			<Outlet />
			<Footer />
		</div>
	)
}

export default Layout
