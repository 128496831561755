/** @format */

import React from "react"
import { MdCall } from 'react-icons/md';
import { MdAttachEmail } from 'react-icons/md';
import { RiVipDiamondFill, RiFacebookBoxFill, RiInstagramFill, RiLinkedinBoxFill } from 'react-icons/ri';
import { BiCopyright } from 'react-icons/bi';
import { Link } from 'react-router-dom'




const Footer = () => {
	return ( 

		<div id="footer">
			<div className="footer-links">
				<div className="box chap-footer-logo">
					<h3><RiVipDiamondFill /> IDYZZ</h3>
				</div>
				<div className="box help-links">
					<h4>Helpful Links</h4>
					<p><Link to= "/" style={{color: 'rgba(211, 210, 210, 0.83)', textDecoration: 'none'}}>Home</Link></p>
					{/* <p><Link to= "/flight_home" style={{color: 'rgba(211, 210, 210, 0.83)', textDecoration: 'none'}}>About</Link></p>
					<p><Link to= "/flight_home" style={{color: 'rgba(211, 210, 210, 0.83)', textDecoration: 'none'}}>Shop</Link></p> */}
					<p><Link to= "/entertainment" style={{color: 'rgba(211, 210, 210, 0.83)', textDecoration: 'none'}}>Entertainment</Link></p>
					<p><Link to= "/flight_home" style={{color: 'rgba(211, 210, 210, 0.83)', textDecoration: 'none'}}>Travel</Link></p>
				</div>
				<div className="box footer-services">
					<h4>Our Services</h4>
					<p>Networking</p>
					<p>Wifi Interconnection</p>
					<p>DSTV/GOTV Services</p>
					<p>Flight Booking</p>
				</div>
				<div className="box call-now">
					<h4>Call Us Now</h4>
					<p><MdCall className="call-icon"/> <a href="tel:+2348115783395">+234 8115783395</a></p>
					<p><MdCall className="call-icon"/> <a href="tel:+2349123171209">+234 9123171209</a></p>
					<p><MdCall className="call-icon"/> <a href="tel:+2347049573893">+234 7049573893</a></p>
					<p><a href="mailto:ticketing@idyzzg.com"><MdAttachEmail className="call-icon"/> ticketing@idyzzg.com</a></p>

				</div>
			</div>
			<div className="footer-info">
				<div className="box footer-socials">
					<p><RiFacebookBoxFill className="icon"/></p>
					<p><RiInstagramFill className="icon"/></p>
					<p><RiLinkedinBoxFill className="icon"/></p>
				</div>
				<div className="box footer-info-mid" style={{fontStyle: "italic", color: "white"}}><p><a style={{color: "white"}} href="https://www.linkedin.com/in/winnie-obunabor/">Design by WinnieDesigns</a></p></div>
				<div className="box footer-info-right"><p><BiCopyright /> ChapIdyzz 2022 All Rights Reserved</p></div>
			</div>
		</div>


	)
}

export default Footer
