import React, { useState } from "react";
import Contact from "components/index/Contact";
import Items from "components/Items";

const ShopPage = () => {
  const [shouldLoad, setShouldLoad] = useState(false);

  const toggleModal = ()=>{
    
  };


  return (
    <main>
      <h1 className="shop-header">Shop</h1>
      <Items loadMore={shouldLoad} toggleModal={toggleModal} />
      <div className="shop-viewMore">
        <button onClick={() => setShouldLoad(true)}>View more</button>
      </div>
      <Contact />
    </main>
  );
};

export default ShopPage;
