import React, { useState, useEffect } from "react";
import { useBottomScrollListener } from "react-bottom-scroll-listener";

import useGetProducts from "store/products";
import { useGetCart } from "store/cart";
import Item from "./Item";
import Spinner from "./Spinner";

const Items = (props) => {
  const { loadMore, toggleModal } = props;
  const [productsDisplay, setProductsDisplay] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [limit, setLimit] = useState(0);
  const [cartProductIds, setCartProductIds] = useState([]);

  const { getData, isLoading, isError } = useGetProducts({
    datum: { quantity: loadMore ? "" : "firstpage" },
    shouldFetch: true,
  });
  const { cart, isLoading: isLoadingCart } = useGetCart();
  const products = getData?.data;
  useEffect(() => {
    if (products) {
      if (loadMore) {
        setProductsDisplay(products?.slice(0, productsDisplay.length));
        fetchMoreListItems();
      } else {
        setProductsDisplay(products?.slice(limit, limit + 12));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);
  useEffect(() => {
    if (!isLoadingCart && cart) {
      setCartProductIds(cart?.map((item) => item.productId));
    }
  }, [cart, isLoadingCart]);
  useEffect(() => {
    setProductsDisplay((prevState) => {
      if (!products) return [...prevState];
      return [...prevState, ...products?.slice(limit, limit + 12)];
    });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit]);
  const fetchMoreListItems = () => {
    setIsFetching(true);
    setTimeout(() => {
      if (loadMore) {
        setLimit(limit + 12);
      } else if (limit < 100) {
        setLimit(limit + 12);
      }
      setIsFetching(false);
    }, 2000);
  };
  const itemsRef = useBottomScrollListener(fetchMoreListItems);
  if (isError) return <div>failed to load</div>;
  return (
    <>
      <div className="shop-items" ref={itemsRef}>
        {productsDisplay.map((product) => (
          <Item
            key={product.id}
            item={product}
            addToCart={toggleModal}
            cta={
              cartProductIds.includes(product.id) ? "updateCart" : "addToCart"
            }
            cartQuantity={
              cartProductIds.includes(product.id)
                ? cart?.find((item) => item.productId === product.id)?.quantity
                : 1
            }
            inventoryQuantity={0}
          />
        ))}
      </div>
      {(isFetching || isLoading) && <Spinner />}
    </>
  );
};

export default Items;
