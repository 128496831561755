// import Nav from "./Nav"
// import back from "../Images/headerimg.png"
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const Header = () => {
  return (
    <Carousel renderArrowsWhenDisabled={false} itemClass="carousel-item-padding-0-px" responsive={responsive} showDots={false} infinite autoPlay >
      {/* {[1, 2, 3, 4].map(() => (
        <div className="header">
          <div className="header-content">
            <h3>IT NETWORK AND SERVICES</h3>
          </div>
        </div>
      ))} */}
      
      <div className="header">
        <div className="header-content headerimg1">
          <h3>IT NETWORK AND SERVICES</h3>
        </div>
      </div>
      <div className="header">
        <div className="header-content headerimg2">
          <h3>SUBSCRIBE TO DSTV OR GTV NOW</h3>
        </div>
      </div>
      <div className="header">
        <div className="header-content headerimg3">
          <h3>BOOK YOUR FLIGHT WITH US</h3>
        </div>
      </div>
    </Carousel>
  );
};

export default Header;
