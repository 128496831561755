import useSWR from "swr";
import url from "url";
import axiosInstance from "store/store";

const fetcher = (url) => axiosInstance.get(url).then((res) => res.data);

const useGetProducts = ({ datum, shouldFetch = false }) => {
  // console.log(shouldFetch);
  const path = url.format({
    pathname: `api/products`,
    query: {
      ...datum,
    },
  });
  const { data, error } = useSWR(shouldFetch ? path : null, fetcher);

  return {
    getData: data,
    isLoading: !error && !data,
    isError: error,
  };
};
export default useGetProducts;
