import React, { useState, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import CartItems from 'components/index/CartItems';
import Spinner from 'components/Spinner';
import { useVerifyOrder, useGetCart, useCheckoutOrder } from 'store/cart';
// solve spinner issue with verification
const Checkout = () => {
  const [totalAmount, setTotalAmount] = useState(0);
  const [email, setEmail] = useState("");
  const [searchParams, setSearchParams] = useState(new URLSearchParams());
  const [checkoutOrder, setCheckoutOrder] = useState(false);
  const [verifyOrder, setVerifyOrder] = useState(false);

  const location = useLocation();
  useMemo(() => setSearchParams(new URLSearchParams(location.search)), [location.search])
  // const searchParams = new URLSearchParams(location.search);
  const { cart: cartData, isLoading: isLoadingCart } = useGetCart();
  const {checkoutData} = useCheckoutOrder({
    postData: {
      email,
      products: cartData?.map(cartItem=>{
        return {
          quantity: cartItem.quantity,
          PartNumber: cartItem.product.PartNumber
        }
      }),
      callback_url: window.location.href,
    },
    shouldFetch: !isLoadingCart && checkoutOrder
  });
  const {verifyData, isLoading: isLoadingVerify} = useVerifyOrder({
    reference: searchParams.get("reference"),
    shouldFetch: searchParams.get("reference") && verifyOrder
  });
  useEffect(() => {
    if (checkoutOrder) {
      setTimeout(() => {
        setCheckoutOrder(false);
      }, 100);
    }
  }, [checkoutOrder]);
  useEffect(() => {
    if (checkoutData?.data.authorization_url) {
      window.location.replace(checkoutData.data.authorization_url);
    }
  }, [checkoutData]);
  useEffect(() => {
    if (searchParams.get("reference")) {
      setVerifyOrder(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleCheckout = (event)=>{
    event.preventDefault();
    setCheckoutOrder(true);
  };
  const handleEmailChange = (event)=>{
    setEmail(event.target.value);
  };
  return (
    <div id='checkout'>
        <h4 className='shopping-page'>Shopping Cart.</h4>
        <div className="shop-cart">
            <div className="cart-items">
                <CartItems totalAmount={totalAmount} setTotalAmount={setTotalAmount} />
            </div>
            <div className="payment">
                {
                  searchParams.get("reference")
                  ? (
                    isLoadingVerify && !verifyData
                      ? (
                      <div>
                        <Spinner />
                        <p>Verifying</p>
                      </div>
                      )
                      : <p>Verified</p>)
                  :(
                  <>
                    <h4>Checkout</h4>
               
                    <form className='checkout-form' action="">
                      <label htmlFor="useremail">Email</label>
                      <input id='useremail' className='checkout-input' type="email" name='useremail' value={email} onChange={handleEmailChange} />
    
                      <button onClick={handleCheckout}>Pay with Paystack</button>
                      
                    </form>
                  </>)
                }
                
            </div>
        </div>
    </div>
  )
}

export default Checkout;