import Aos from "aos";
import "aos/dist/aos.css";
import React, { useEffect, useState } from "react";
import Contact from "../components/index/Contact";
// import flightbooking from "assets/images/flightbooking.png";
// import helpandpriv from "assets/images/helpandpriv.png";
// import servicessupport from "assets/images/servicessupport.png";
import BookFlight from 'components/index/BookFlight';

const FlightHome = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  // data-aos-delay="200"

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  return (
    <div>
      <div className="flight-header-div">
        <div className="flight-header">
          <h4>Welcome to Chap Travels</h4>
          <p>your comfort is our priority</p>

          <button className='openModalBtn' onClick={() => handleOpen()}>Get Started</button>

          <BookFlight open={open} handleClose={handleClose}/>
        </div>
      </div>

      {/* THE CODE BELOW IS THE REST OF THE CONTENT FOR THE FLIGHT PAGE */}

      {/* <h1 className="how-it-works">How it Works</h1>
      <hr style={{ margin: "0 1.5rem 1rem 2rem" }} /> */}
      {/* ------------------------------------------------------------------------------ */}

      {/* <div className="flight-booking">
        <div data-aos="fade-up" className="flight-booking-text lift">
          <h4>01. Flight Booking</h4>
          <p>
            we book only the most affordable fights for your next travel
            destination. Our servicces are both local and international and we
            ensure that you never have to waste hours trying to find affordable
            flights anymore; we do the work for you
          </p>
        </div>
        <div data-aos="fade-down" className="flight-booking-img lift">
          <img src={flightbooking} alt="" />
        </div>
      </div> */}
      {/* --------------------------------------------------------------------------------- */}

      {/* <div className="flight-booking">
        <div data-aos="fade-right" className="flight-booking-img lift">
          <img src={servicessupport} alt="" />
        </div>
        <div data-aos="fade-left" className="flight-booking-text support lift">
          <h4>02. Services Support</h4>
          <p>
            we book only the most affordable fights for your next travel
            destination. Our servicces are both local and international and we
            ensure that you never have to waste hours trying to find affordable
            flights anymore; we do the work for you
          </p>
        </div>
      </div> */}
      {/* --------------------------------------------------------------------------------- */}
      {/* <div className="flight-booking">
        <div data-aos="fade-down" className="flight-booking-text lift">
          <h4>03. Privacy and Help</h4>
          <p>
            we book only the most affordable fights for your next travel
            destination. Our servicces are both local and international and we
            ensure that you never have to waste hours trying to find affordable
            flights anymore; we do the work for you
          </p>
        </div>
        <div data-aos="fade-up" className="flight-booking-img lift">
          <img src={helpandpriv} alt="" />
        </div>
      </div> */}
      {/* --------------------------------------------------------------------------------- */}

      {/* <div className="flight-quote">
        <p>
          “The world is a book and those who do not travel read only one page.”{" "}
        </p>
        <p>- Saint Augustine</p>
      </div> */}

      <Contact />
    </div>
  );
};

export default FlightHome;
