import React, { useEffect } from "react";
import Aos from "aos";
import { useFormik } from "formik";
import * as Yup from "yup";
import emailjs from "@emailjs/browser";

import Map from "components/Map/Map";

const Contact = () => {
  const { handleSubmit, handleChange, values /* isSubmitting */ } = useFormik({
    initialValues: {
      name: ``,
      message: "",
      email: "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("required"),
      message: Yup.string().required("required"),
      email: Yup.string().email().required("required"),
    }),
    onSubmit: (values, { setSubmitting, resetForm }) => {
      setSubmitting(true);

      let newValues = {
        to_name: "Chapidyzz",
        from_name: values.name,
        from_email: values.email,
        message: values.message,
        reply_to: "otagera@gmail.com",
      };
      emailjs
        .send(
          process.env.REACT_APP_EMAILJS_SERVICE_ID,
          process.env.REACT_APP_EMAILJS_CONTACT_TEMPLATE_ID,
          newValues,
          process.env.REACT_APP_EMAILJS_PUBLIC_KEY
        )
        .then(
          (response) => {
            console.log(response);
            if (response.status === 200) {
              resetForm();
              setSubmitting(true);
            }
          },
          (err) => {
            console.log(err);
          }
        );
    },
  });
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <div id="contact">
      <div className="form-text">
        <h4>Lets get in touch!</h4>
      </div>

      <div className="contact-container">
        {/* FORM AREA */}
        <div data-aos="fade-up" className="contact-form contact-box">
          <form className="contact-form" onSubmit={handleSubmit}>
            <input
              className="contact-input"
              placeholder="Name"
              type="text"
              name="name"
              onChange={handleChange}
              value={values.name}
            />
            <input
              className="contact-input"
              placeholder="Email"
              type="text"
              name="email"
              onChange={handleChange}
              value={values.email}
            />
            <textarea
              cols={10}
              className="contact-area"
              placeholder="Message"
              name="message"
              type="text"
              onChange={handleChange}
              value={values.message}
            />
            <button className="common-btn" type="submit">
              Submit
            </button>
          </form>
        </div>
        {/* MAP AREA */}
        <div
          data-aos="fade-up"
          data-aos-delay="100"
          className="contact-map contact-box"
        >
          <Map zoomLevel={17} />
        </div>
      </div>
    </div>
  );
};

export default Contact;
