import useSWR from "swr";
import axiosInstance from "store/store";

const fetcherPost = (url, data) =>
  axiosInstance.post(url, data).then((res) => res.data);

const fetcherGet = (url) => axiosInstance.get(url).then((res) => res.data);

export const useGetCart = () => {
  const { data, error } = useSWR("getCarts", () =>
    JSON.parse(localStorage.getItem("cart")) === null
      ? []
      : JSON.parse(localStorage.getItem("cart"))
  );
  console.log(data, error);
  return {
    cart: data,
    isLoading: !error && !data,
    isError: error,
  };
};

export const useAddCartItem = ({ product, quantity, shouldFetch = false }) => {
  const { data, error } = useSWR(shouldFetch ? "addCartItem" : null, () => {
    const cart = JSON.parse(localStorage.getItem("cart")) || [];
    let productExists = false;
    for (let item of cart) {
      if (item.product.id === product.id) {
        item.quantity = quantity;
        productExists = true;
        break;
      }
    }
    if (!productExists) {
      cart.push({
        quantity: 1,
        product,
      });
    }
    return cart;
  });
  data && localStorage.setItem("cart", JSON.stringify(data));
  return {
    cart: data,
    isLoading: !error && !data,
    isError: error,
  };
};

export const useRemoveCartItem = ({ product, shouldFetch = false }) => {
  const { data, error } = useSWR(shouldFetch ? "removeCartItem" : null, () => {
    const cart = JSON.parse(localStorage.getItem("cart")) || [];
    for (let index = 0; index < cart.length; index++) {
      const item = cart[index];
      if (item.product.id === product.id) {
        cart.splice(index, 1);
        break;
      }
    }
    return cart;
  });
  data && localStorage.setItem("cart", JSON.stringify(data));
  return {
    cart: data,
    isLoading: !error && !data,
    isError: error,
  };
};

export const useChangeCartItemQuantity = ({
  product,
  changeType,
  shouldFetch = false,
}) => {
  // console.log(product);
  const { data, error } = useSWR(
    shouldFetch ? "changeCartItemQuantity" : null,
    () => {
      const cart = JSON.parse(localStorage.getItem("cart")) || [];
      for (let item of cart) {
        console.log(item);
        if (item.product.id === product.id) {
          if (changeType === "increase") {
            item.quantity = item.quantity + 1;
          } else if (changeType === "decrease") {
            item.quantity = item.quantity - 1;
          }
          break;
        }
      }
      return cart;
    }
  );
  data && localStorage.setItem("cart", JSON.stringify(data));
  return {
    cart: data,
    isLoading: !error && !data,
    isError: error,
  };
};

export const useCheckoutOrder = ({ postData, shouldFetch = false }) => {
  const { data, error } = useSWR(
    shouldFetch ? [`api/order/checkout`, postData] : null,
    fetcherPost
  );

  return {
    checkoutData: data,
    isLoading: !error && !data,
    isError: error,
  };
};

export const useVerifyOrder = ({ reference, shouldFetch = false }) => {
  const { data, error } = useSWR(
    shouldFetch ? `api/order/verify/${reference}` : null,
    fetcherGet
  );

  return {
    verifyData: data,
    isLoading: !error && !data,
    isError: error,
  };
};
