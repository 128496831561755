import React, { useState } from "react";
import { useFormik } from "formik";
// import * as Yup from 'yup';
import PlacesAutoComplete from "./PlacesAutoComplete";
import {
  Form,
  Button,
  InputGroup,
  InputNumber,
  Modal,
  RadioGroup,
  Radio,
  DatePicker,
  DateRangePicker,
  Input,
  Grid,
  Row,
  Col,
} from "rsuite";
import { format } from "date-fns";
import emailjs from "@emailjs/browser";

function BookFlight({ open, handleClose }) {
  const [tripType, setTripType] = useState("One Way");
  const [adults, setAdults] = React.useState(0);
  const [children, setChildren] = React.useState(0);
  const [infants, setInfants] = React.useState(0);

  const handleAdultMinus = () => {
    setAdults(parseInt(adults, 10) - 1);
    setFieldValue("adults", parseInt(adults, 10) - 1);
  };
  const handleAdultPlus = () => {
    setAdults(parseInt(adults, 10) + 1);
    setFieldValue("adults", parseInt(adults, 10) + 1);
  };

  const handleChildrenMinus = () => {
    setChildren(parseInt(children, 10) - 1);
    setFieldValue("children", parseInt(children, 10) - 1);
  };
  const handleChildrenPlus = () => {
    setChildren(parseInt(children, 10) + 1);
    setFieldValue("children", parseInt(children, 10) + 1);
  };

  const handleInfantsMinus = () => {
    setInfants(parseInt(infants, 10) - 1);
    setFieldValue("infants", parseInt(infants, 10) - 1);
  };
  const handleInfantsPlus = () => {
    setInfants(parseInt(infants, 10) + 1);
    setFieldValue("infants", parseInt(infants, 10) + 1);
  };

  const { handleSubmit, setFieldValue, isSubmitting } = useFormik({
    initialValues: {
      addressFrom: `Lagos`,
      addressTo: `Ibadan`,
      tripType: "",
      dateRange: null,
      date: null,
      adults: adults,
      children: children,
      infants: infants,
      fullname: "",
      email: "",
      phone: "",
    },
    // validationSchema: Yup.object().shape({
    //   // addressFrom: Yup.date().required('required'),
    //   // addressTo: Yup.date().required('required')
    //   // tripType: Yup.string().required('required'),
    //   // fullname: Yup.string().required('required'),
    // }),
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true);

      let newValues;
      if (tripType === "One Way") {
        delete values["dateRange"];
        newValues = {
          ...values,
          date: format(new Date(values.date), "dd MMM, yyyy"),
        };
      } else {
        delete values["date"];
        newValues = {
          ...values,
          dateRange: {
            depatureDate: format(new Date(values.dateRange[0]), "dd MMM, yyyy"),
            returnDate: format(new Date(values.dateRange[1]), "dd MMM, yyyy"),
          },
        };
      }

      const response = await emailjs.send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_BOOKFLIGHT_TEMPLATE_ID,
        newValues,
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY
      );

      if (response.status === 200) {
        handleClose();
        resetForm();
        setSubmitting(true);
      }
    },
  });

  return (
    <Modal open={open} onClose={handleClose} size="sm">
      <Modal.Header>
        <Modal.Title>Book Flight</Modal.Title>
      </Modal.Header>
      <Form fluid>
        <Modal.Body>
          <Grid fluid>
            <Row>
              <Col xs={24} md={12}>
                <Form.Group controlId="">
                  <Form.ControlLabel>Address</Form.ControlLabel>
                  <PlacesAutoComplete
                    fieldName={"addressFrom"}
                    setFieldValue={setFieldValue}
                  />
                </Form.Group>
              </Col>
              <Col xs={24} md={12}>
                <Form.Group controlId="">
                  <Form.ControlLabel>Destination</Form.ControlLabel>
                  <PlacesAutoComplete
                    fieldName={"addressTo"}
                    setFieldValue={setFieldValue}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Grid>

          <RadioGroup
            className="trip-type"
            name="tripType"
            defaultValue="One Way"
            onChange={(value) => {
              setTripType(value);
              setFieldValue("tripType", value);
            }}
            inline
          >
            <Radio value="One Way">One Way</Radio>
            <Radio value="Round Trip">Round Trip</Radio>
          </RadioGroup>

          {tripType === "One Way" && (
            <Form.Group controlId="">
              <Form.ControlLabel>Date</Form.ControlLabel>
              <DatePicker
                format="yyyy-MM-dd"
                onChange={(value) => setFieldValue("date", value)}
              />
            </Form.Group>
          )}

          {tripType === "Round Trip" && (
            <Form.Group controlId="" xsHidden>
              <Form.ControlLabel>Date</Form.ControlLabel>
              <DateRangePicker
                format="yyyy-MM-dd"
                onChange={(value) => setFieldValue("dateRange", value)}
              />
            </Form.Group>
          )}

          <h6 className="input-title">Travellers</h6>
          <Grid fluid>
            <Row>
              <Col xs={12} md={8}>
                <Form.ControlLabel>Adult</Form.ControlLabel>
                <InputGroup>
                  <InputGroup.Button onClick={handleAdultMinus}>
                    -
                  </InputGroup.Button>
                  <InputNumber
                    className={"custom-input-number"}
                    value={adults}
                    onChange={setAdults}
                  />
                  <InputGroup.Button onClick={handleAdultPlus}>
                    +
                  </InputGroup.Button>
                </InputGroup>
              </Col>
              <Col xs={12} md={8}>
                <Form.ControlLabel>Children</Form.ControlLabel>
                <InputGroup>
                  <InputGroup.Button onClick={handleChildrenMinus}>
                    -
                  </InputGroup.Button>
                  <InputNumber
                    className={"custom-input-number"}
                    value={children}
                    onChange={setChildren}
                  />
                  <InputGroup.Button onClick={handleChildrenPlus}>
                    +
                  </InputGroup.Button>
                </InputGroup>
              </Col>
              <Col xs={12} md={8}>
                <Form.ControlLabel>Infants</Form.ControlLabel>
                <InputGroup>
                  <InputGroup.Button onClick={handleInfantsMinus}>
                    -
                  </InputGroup.Button>
                  <InputNumber
                    className={"custom-input-number"}
                    value={infants}
                    onChange={setInfants}
                  />
                  <InputGroup.Button onClick={handleInfantsPlus}>
                    +
                  </InputGroup.Button>
                </InputGroup>
              </Col>
            </Row>
          </Grid>

          <Form.Group controlId="" className="user-cred">
            <Form.ControlLabel>Full Name</Form.ControlLabel>
            <Input
              placeholder="Full Name"
              type="fullname"
              onChange={(value) => setFieldValue("fullname", value)}
            />
          </Form.Group>

          <Grid fluid className="user-cred">
            <Row>
              <Col xs={24} md={12}>
                <Form.Group controlId="">
                  <Form.ControlLabel>Email</Form.ControlLabel>
                  <Input
                    placeholder="Email"
                    type="email"
                    onChange={(value) => setFieldValue("email", value)}
                  />
                </Form.Group>
              </Col>
              <Col xs={24} md={12}>
                <Form.Group controlId="">
                  <Form.ControlLabel>Phone Number</Form.ControlLabel>
                  <Input
                    type="text"
                    placeholder="Phone Number"
                    onChange={(value) => setFieldValue("phone", value)}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Grid>
        </Modal.Body>

        <Modal.Footer>
          <Button
            onClick={handleSubmit}
            appearance="primary"
            loading={isSubmitting}
          >
            Book Flight
          </Button>
          <Button onClick={handleClose} appearance="subtle">
            Cancel
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default BookFlight;
