import React, { useState, useEffect } from "react";

import { useGetCart } from "store/cart";
import CartItem from "./CartItem";
import Spinner from "../Spinner";

const CartItems = ({ totalAmount, setTotalAmount }) => {
  const [cart, setCart] = useState([]);
  const { cart: cartData, isLoading: isLoadingCart } = useGetCart();
  useEffect(() => {
    if (cartData) {
      setCart(cartData);
      setTotalAmount(
        cartData?.reduce((prev, item) => {
          if (
            item.product.MSRP &&
            typeof item.product.MSRP === "string" &&
            item.product.MSRP.includes("$")
          ) {
            return (
              Number.parseInt(item.product.MSRP.split("$")[1]) * item.quantity +
              prev
            );
          }
          return Number.parseInt(item.product.MSRP) * item.quantity + prev;
        }, 0)
      );
    }
  }, [cartData, setTotalAmount]);

  return (
    // Get better table,
    // redesign, add more info
    <div>
      <div className="wrapper">
        <div className="cart-table-label">
          <p></p>
          <p>Product ID</p>
          <p></p>
          <p>Quantity</p>
          <p></p>
          <p>Amount</p>
        </div>

        <div>
          {isLoadingCart ? (
            <Spinner />
          ) : cart?.length > 0 ? (
            cart?.map((item) => (
              <CartItem key={item.product.id} cartData={item} />
            ))
          ) : (
            <p className="empty-cart">Cart empty</p>
          )}
        </div>
        <CartItem totalAmount={`$${totalAmount}`} total={true} />
      </div>
    </div>
  );
};

export default CartItems;
