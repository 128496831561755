import React from "react";
// import Aos from "aos";
// import "aos/dist/aos.css";
import { useEffect } from "react";

const About = () => {
  useEffect(() => {
    // Aos.init({ duration: 1000 });
  }, []);

  return (
    <div id="about">
      <div className="about-content">
        <h4 data-aos="fade-up">What We Do</h4>
        <p data-aos="fade-up" data-aos-delay="100">
          Talk to us when you need to fly anywhere, for your IT Networking needs and your TV entertainment
        </p>
        {/* <button data-aos="fade-up" data-aos-delay="200">
          READ MORE
        </button> */}
      </div>
    </div>
  );
};

export default About;
