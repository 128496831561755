import React, { useEffect, useState } from "react";
import { useSWRConfig } from "swr";
import { ImBin } from "react-icons/im";
import { GrSubtractCircle, GrAddCircle } from "react-icons/gr";

import { useRemoveCartItem, useChangeCartItemQuantity } from "store/cart";

const CartItem = ({ cartData, totalAmount, total = false }) => {
  const [removeCartItem, setRemoveCartItem] = useState(false);
  const [addCartItem, setAddCartItem] = useState(false);
  const [subtractCartItem, setSubtractCartItem] = useState(false);
  const { mutate } = useSWRConfig();
  useRemoveCartItem({
    product: cartData?.product,
    shouldFetch: removeCartItem,
  });
  useChangeCartItemQuantity({
    product: cartData?.product,
    changeType: "increase",
    shouldFetch: addCartItem,
  });
  useChangeCartItemQuantity({
    product: cartData?.product,
    changeType: "decrease",
    shouldFetch: subtractCartItem,
  });
  useEffect(() => {
    if (removeCartItem) {
      setTimeout(() => {
        mutate("getCarts");
      }, 500);
    }
    if (addCartItem) {
      setTimeout(() => {
        mutate("getCarts");
      }, 500);
    }
    if (subtractCartItem) {
      setTimeout(() => {
        mutate("getCarts");
      }, 500);
    }
  }, [removeCartItem, addCartItem, subtractCartItem, mutate]);
  useEffect(() => {
    if (addCartItem) {
      setTimeout(() => {
        setAddCartItem(false);
      }, 100);
    }
    if (subtractCartItem) {
      setTimeout(() => {
        setSubtractCartItem(false);
      }, 100);
    }
  }, [addCartItem, subtractCartItem]);
  return (
    <div className={`cart-data${total ? " total" : ""}`}>
      {total ? (
        <p>Total</p>
      ) : (
        <ImBin
          onClick={() => {
            setRemoveCartItem(true);
          }}
        />
      )}
      {total ? (
        <p> </p>
      ) : (
        <>
          <p>{cartData.product.id}</p>
          {total || cartData.quantity < 2 ? (
            <p> </p>
          ) : (
            <GrSubtractCircle
              onClick={() => {
                setSubtractCartItem(true);
              }}
            />
          )}
          <p>{cartData.quantity}</p>
          {total ? (
            <p> </p>
          ) : (
            <GrAddCircle
              onClick={() => {
                setAddCartItem(true);
              }}
            />
          )}
        </>
      )}
      <p>{total ? totalAmount : cartData.product.MSRP}</p>
    </div>
  );
};

export default CartItem;
