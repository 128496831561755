import React, { useEffect } from "react";
import Aos from "aos";
import { GiNetworkBars } from "react-icons/gi";
import { MdNetworkCheck } from "react-icons/md";
import { SiYourtraveldottv } from "react-icons/si";

const Services = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <div id="services">
      <div className="services-content">
        <div data-aos="fade-up" className="service">
          <GiNetworkBars className="service-icon" />
          <p>
            we provide high end networking services and connection for hotels,
            offices and small businesses
          </p>
          <h4>NETWORKING</h4>
        </div>

        <div data-aos="fade-up" data-aos-delay="200" className="service">
          <MdNetworkCheck className="service-icon" />
          <p>
            We give you the best TV entertainment experience
          </p>
          <h4>DSTV/GOTV</h4>
        </div>

        <div data-aos="fade-up" data-aos-delay="400" className="service">
          <SiYourtraveldottv className="service-icon" />
          <p>
            Chap Travels is in the business of providing the best of cheap fares, excellent pre-flight, and post-flight logistics
          </p>
          <h4>TRAVELS</h4>
        </div>
      </div>
    </div>
  );
};

export default Services;
